.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 250px;
}

.not-found{
    display: flex;
    min-width: 900px;
    width: 900px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.not-found h1{
    font-size: 100px;
}

.not-found a{
    text-decoration: none;
    color: #fff;
    background-color: darkblue;
    padding: 10px;
    border-radius: 5px;
    border: 0;
    margin-top: 50px;
}