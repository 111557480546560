.card-goup{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.logo-rede{
    display: flex;    
    alignItems: center; 
    padding-left: 10px; 
    margin-top: 10px;
}

.logo{
    cursor: pointer;
}

.siga-redes h1{
    font-size: 12px;
}