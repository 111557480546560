.loading {
    display: inline-block;
    transform: translateZ(1px);
    // height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loading > img {
    display: inline-block;
    width: 64px;
    height: 64px;
    margin: 8px;
    animation: loading 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
@keyframes loading {
    0%,
    100% {
        animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
    }
    0% {
        transform: rotateY(0deg);
    }
    50% {
        transform: rotateY(720deg);
        animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }
    100% {
        transform: rotateY(1080deg);
    }
}
