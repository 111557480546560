
.login-container{
  display: flex;  
  padding: 8vh;
  box-sizing: border-box;
  font-family: sans-serif;
  width: 100%;
  background-color: #ffffff;
  flex-direction: column;
  align-items: center;
}

.custom-field {
  margin-top: 15px;
}
.custom-field a { 
  font-size: 11px;
  margin-left: 60px;
}

.login-card{  
  display: flex;
  margin-top: 50px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}