
.pass-container{
  display: flex;  
  padding: 15vh;
  box-sizing: border-box;
  font-family: sans-serif;
  width: 100%;
  background-color: #ffffff;
  flex-direction: column;
  align-items: center;
}

.recovery-button {
  width:300px;
}

.pass-card{  
  display: flex;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.return-login{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 5vh;
}

.text-input{
  margin-top: 20px;
  width:300px;
}